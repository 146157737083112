import Vue from "vue";
import Vuex from "vuex";
import isLoadedStatus from "./modules/isLoadedStatus";
import selectedSku from "./modules/selectedSku";
import lastUser from "./modules/lastUser";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    isLoadedStatus,
    selectedSku,
    lastUser,
  },
  plugins: [createPersistedState()],
});
