import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap";
import "@/assets/css/style.scss";
require("bootstrap-icons/font/bootstrap-icons.css");

import AOS from "aos";
import "aos/dist/aos.css";
import {
  API2,
  payment,
  CONTACT_US,
  LOAD_API,
  APPLICATION_API,
} from "../http-common";

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask, { masked: false });

// Vue.use(AOS);
// import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

// import VueFbCustomerChat from "vue-fb-customer-chat";
import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.config.productionTip = false;
// Vue.use(VueFbCustomerChat, {
//   page_id: 546793246237178, //  change 'null' to your Facebook Page ID,
//   theme_color: "#2d0f4b", // theme color in HEX
//   locale: "en_US", // default 'en_US'
// });
Vue.use({
  install(Vue) {
    // Vue.prototype.$api = API;
    Vue.prototype.$api2 = API2;
    Vue.prototype.$payment = payment;
    Vue.prototype.$contact = CONTACT_US;
    Vue.prototype.$load = LOAD_API;
    Vue.prototype.$application = APPLICATION_API;
  },
});

const options = {
  confirmButtonColor: "#29b198 ",
  cancelButtonColor: "#ff7674",
  customClass: {
    confirmButton: "btn btn-jungle-green rounded-pill px-5",
    cancelButton: "btn btn-link rounded-pill px-5",
  },
};

Vue.use(VueSweetalert2, options);

new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
