<template>
  <div id="app">
    <!-- <Navbar /> -->
    <!-- <router-view /> -->
    <router-view v-slot="{ Component, route }">
      <!-- Use any custom transition and fallback to `fade` -->
      <transition :name="route.meta.transition || 'fade'">
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- <Footer /> -->
  </div>
</template>
<script>
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
export default {
  components: {
    // Navbar,
    // Footer,
  },

  mounted() {
    window.addEventListener("load", () => {
      document.querySelector("body").classList.remove("overflow-hidden");
    });
  },

  methods: {
    async getApplicationInfo() {
      await this.$application
        .get("/application-info")
        .then((response) => {
          const res = response.data;
          this.$store.dispatch("ADD_APPLICATION", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  created() {
    this.getApplicationInfo();
  },
};
</script>
<style></style>
