export default {
  state: {
    user: {},
    token: "",
    application: null,
  },

  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload;
    },

    SET_TOKEN: (state, payload) => {
      state.token = payload;
    },

    SET_APPLICATION(state, data) {
      state.application = data;
    },
  },

  actions: {
    ADD_USER: (context, payload) => {
      context.commit("SET_USER", payload);
    },
    ADD_TOKEN: (context, payload) => {
      context.commit("SET_TOKEN", payload);
    },

    ADD_APPLICATION({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_APPLICATION", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  getters: {
    GET_USER: (state) => {
      return state.user;
    },

    GET_TOKEN: (state) => {
      return state.token;
    },

    GET_APPLICATION: (state) => state.application,
  },
};
