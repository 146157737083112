import axios from "axios";
const d = new Date();
export const API = axios.create({
  baseURL: `http://139.135.229.31:10002/api`,
  headers: {
    "Content-Type": "application/json",
    "wsc-timestamp": d.getTime(),
  },
});

export const API2 = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `https://load.s2s.ph/api`
      : "https://load.s2s.ph/api",
  headers: {
    Authorization:
      "E1tSj1xIwt+ZphBhpYbfjPl30PF4l39lQ6knfAVn4C+cY/+8k11VcDe8ELpYxz9auxAuYlb1Kg==",
  },
});

export const payment = axios.create({
  baseURL: "https://payment.s2s.ph/api",
  headers: {
    "Content-Type": "application/json",
    "wsc-timestamp": d.getTime(),
    Authorization:
      "FFWPj+Lq8QWRAcstQF3j/f8NO88vFJYqtv/Mb3N+mP7DYjczXPdtQ5Qy3yEUQDjKJ/dfsIaSRg==",
  },
});

export const CONTACT_US = axios.create({
  baseURL: `https://csp.s2s.ph/api`,
});

export const LOAD_API = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `https://load.s2s.ph/api`
      : "https://load.s2s.ph/api",
});

export const APPLICATION_API = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `https://application.s2s.ph/api`
      : "https://application.s2s.ph/api",
  headers: {
    Authorization:
      "lLVnnPc6pxzaqxiaLt9szzILhtOH+7+6fdG4YkCO9IgesGkKrWQCUpG+6vthe2buqLfjaoAv5w==",
  },
});
