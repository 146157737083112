export default {
  state: {
    isLoaded: false,
  },

  mutations: {
    SET_ISLOADED: (state, value) => {
      state.isLoaded = value.payload.isLoaded;
    },
  },

  getters: {
    IS_LOADED: (state) => {
      return state.isLoaded;
    },
  },

  actions: {
    ADD_IS_LOADED_STATUS: (context, payload) => {
      context.commit("SET_ISLOADED", { payload });
    },
  },
};
