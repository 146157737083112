import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import store from "../store/index";
import { APPLICATION_API } from "./../../http-common";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    meta: {
      title: "Home",
      metaTags: [
        {
          name: "description",
          content: "adasdadadasd",
        },
      ],
    },
  },
  {
    path: "/topup",
    name: "Topup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "gifting" */ "../views/Gifting.vue"),
    meta: { transition: "slide-left" },
  },

  {
    path: "/checkout",
    name: "Checkout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutSummary.vue"),
    meta: { transition: "slide-left" },
  },

  {
    path: "/success",
    name: "Transaction",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "transaction" */ "../views/TransactionStatus.vue"
      ),
    meta: { transition: "slide-left" },
  },

  {
    path: "/failed",
    name: "Failed",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "failed" */ "../views/Failed.vue"),
    meta: { transition: "slide-left" },
  },
  {
    path: "/RetailerHub",
    name: "RetailerHub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "RetailerHub" */ "../views/RetailerHub.vue"),
    meta: { transition: "slide-left" },
  },

  {
    path: "/blasttv",
    name: "BlastTV",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "RetailerHub" */ "../views/BlastTv.vue"),
    meta: { transition: "slide-left" },
  },

  {
    path: "/redirect",
    name: "Redirect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "failed" */ "../views/Redirect.vue"),
    meta: { transition: "slide-left" },
  },
  // {
  //   path: "/data-plans",
  //   name: "DataPlans",
  //   component: () => import("../views/DataPlans.vue"),
  // },
  // {
  //   path: "/coverage",
  //   name: "Coverage",
  //   component: () => import("../views/Coverage.vue"),
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("../views/About.vue"),
  // },
  // {
  //   path: "/careers",
  //   name: "Careers",
  //   component: () => import("../views/Careers.vue"),
  // },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: () => import("../views/Contact.vue"),
  // },

  {
    path: "/download/s2s-agent",
    name: "s2s-agent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "S2sAgent" */ "../views/DownloadPage.vue"),
    meta: { transition: "slide-left" },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (routeTo, routeFrom, next) => {
  await APPLICATION_API.get("/application-info")
    .then((response) => {
      const res = response.data;
      store.dispatch("ADD_APPLICATION", res.data);
    })
    .catch((e) => {
      console.log(e);
    });

  const app = store.getters.GET_APPLICATION || {};
  const buyLoadIsAvailable = app?.settings?.enableBuyLoad;
  const maintenance = app?.maintenance;
  // const buyLoadIsAvailable = false;
  // const maintenance = true;
  // console.log({ buyLoadIsAvailable, maintenance, store });

  if (routeTo.name === "Topup") {
    if (maintenance || !buyLoadIsAvailable) {
      next("/");
    }
  }

  if (routeTo.name === "Checkout") {
    if (maintenance || !buyLoadIsAvailable) {
      next("/");
    }
  }

  next();
});

export default router;
