export default {
  state: {
    sku: {},
  },

  mutations: {
    SET_SKU: (state, payload) => {
      state.sku = payload;
    },
  },

  actions: {
    ADD_SKU: (context, payload) => {
      context.commit("SET_SKU", payload);
    },
  },

  getters: {
    GET_SKU: (state) => {
      return state.sku;
    },
  },
};
